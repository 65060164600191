import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
// import useChampionsFilter from "@/game-lol/utils/useChampionsFilter.jsx";
// import useChampionsOverview from "@/game-lol/utils/useChampionsOverview.jsx";
// import { useRoute } from "@/util/router-hooks.mjs";
import GridList from "@/feature-reskin-probuilds-net/components/GridList.jsx";
import Layout from "@/feature-reskin-probuilds-net/components/Layout.jsx";
import championLink from "@/feature-reskin-probuilds-net/utils/champion-link.mjs";
import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
// import { ROLE_SYMBOL_TO_STR } from "@/game-lol/constants/constants.mjs";
import useStaticChampions from "@/game-lol/utils/use-static-champions.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

type StaticChampion = {
  id: number;
  key: string;
  name: string;
};

function Champions() {
  const { t } = useTranslation();
  // const { searchParams } = useRoute();
  // const { ...filterParams } = useChampionsFilter({
  //   tab: "overview",
  // });

  const {
    lol: { proBuildChampionsReport },
  } = useSnapshot(readState);

  const champions = useStaticChampions();
  const proChampions = useMemo(() => {
    if (!proBuildChampionsReport) return {};
    return proBuildChampionsReport.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});
  }, [proBuildChampionsReport]);

  // const { rows } = useChampionsOverview(searchParams, filterParams.role);

  // const championsRoles = (rows || []).reduce((acc, curr) => {
  //   if (!curr) return acc;
  //   const name = curr[1]?.value;
  //   const role = curr[0]?.value;
  //   if (!acc[name]) acc[name] = [];
  //   acc[name].push(role);
  //   return acc;
  // }, {});

  const championsList = useMemo(() => {
    return Object.values(champions)
      .map((champion: StaticChampion) => {
        const { id } = champion;
        return {
          // id: champion.id,
          // name: champion.name,
          // roles: championsRoles[champion.name] || [],
          link: championLink(id),
          image: <ChampionImg id={id} size={48} />,
          title: champions[id]?.name || id,
          subtitle: proChampions[id]?.matchCount
            ? t("lol:countGame_plural", "{{count}} Games", {
                count: proChampions[id].matchCount.toLocaleString(getLocale()),
              })
            : null,
          name: champions[id]?.name,
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [t, champions, proChampions]);

  return (
    <Layout title={t("lol:champions", "Champions")}>
      <GridList items={championsList} />
    </Layout>
  );
}

export default Champions;

export function meta() {
  return {
    title: [
      "probuildsnet:home.meta.title",
      "ProBuilds - LoL Pro Champion Stats, Teams, Builds, Runes, Masteries, Counters and Matchups!",
    ],
    description: [
      "probuildsnet:home.meta.description",
      "ProBuilds provides League of Legends pro champion statistics, guides, builds, runes, masteries, skill orders and counters by role - including Win Rate, Ban Rate, Play Rate and much more!",
    ],
  };
}
